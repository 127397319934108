import Divider from '@components/divider/Divider'
import { CallToActionButton } from '@partials/landingpage/CallToActionButton'
import { CustomerLogos } from '@partials/landingpage/CustomerLogos'
import { HeroImage } from '@partials/landingpage/HeroImage'
import LandingPageContainer from '@partials/landingpage/LandingPageContainer'
import CrmFeatureImage from '@public/images/lp/crm_feature.webp'
import FpdsLogo from '@public/images/lp/fpds_logo.webp'
import GsaEbuy from '@public/images/lp/gsa_ebuy.webp'
import NitaacCiosp3Logo from '@public/images/lp/nitaac_ciosp3_logo.webp'
import OutlookLogo from '@public/images/lp/outlook_logo.webp'
import PipelineFeatureImage from '@public/images/lp/pipeline_feature.webp'
import ReportFeatureImage from '@public/images/lp/report_feature.webp'
import SamLogo from '@public/images/lp/sam_logo.webp'
import SearchFeatureImage from '@public/images/lp/search_feature.webp'
import SharepointLogo from '@public/images/lp/sharepoint_logo.webp'
import TimelineFeatureImage from '@public/images/lp/timeline_feature.webp'
import { InteractiveOpportunityManagementSection } from '@src/partials/landingpage/InteractiveOpportunityManagementSection'
import {
  ProductFeature,
  ProductFeatureProps,
} from '@src/partials/landingpage/ProductFeature'
import Image from 'next/image'
import Link from 'next/link'
import { Fragment } from 'react'
import { FeatureGrid } from './FeatureGrid'
import styles from './LandingPage.module.scss'
import { TestimonialSection } from './TestimonialSection'

const integrationLogos = [
  {
    src: SamLogo,
    alt: 'SAM.gov Logo',
  },
  {
    src: FpdsLogo,
    alt: 'FPDS Logo',
  },
  {
    src: GsaEbuy,
    alt: 'GSA eBuy Logo',
  },
  {
    src: NitaacCiosp3Logo,
    alt: 'NITAAC CIOSP3 Logo',
  },
  {
    src: SharepointLogo,
    alt: 'SharePoint Logo',
  },
  {
    src: OutlookLogo,
    alt: 'Outlook Logo',
  },
]

const defaultProductFeatureSections: ProductFeatureProps[] = [
  {
    name: 'Market Intelligence & Task Order Management ',
    headline: 'All your opportunities in one place',
    descriptionItems: [
      'Search government contract opportunities from SAM.gov, FPDS, and GovWin',
      'AI Chat with pre-loaded documents from SAM.gov',
      'Manage task orders originating from portals (GSA eBuy, CIO-SP3)',
      'Manage task orders originating over email (Seaport, OASIS, and more)',
      'Research contract vehicle trends and pre-RFP opportunites',
      'Add opportunities to the pipeline in a single click',
      'Get notified of new opportunities with saved searches',
    ],
    imageSrc: SearchFeatureImage,
  },
  {
    name: 'Pipeline Management',
    headline: 'Single, easy to use source of truth',
    descriptionItems: [
      'Shipley-ready pipeline and opportunity management built for your workflow',
      'Easily identify and prioritize key opportunities',
      'Efficient communication between BD, proposal, and executive teams in a single place',
    ],
    imageSrc: PipelineFeatureImage,
  },
  {
    name: 'Timeline & Capture Resource Management',
    headline: 'Get more proposals out the door',
    descriptionItems: [
      'Create an accurate timeline of key milestones',
      'Visualize your timeline',
      'Predict resource limitations',
    ],
    imageSrc: TimelineFeatureImage,
  },
  {
    name: 'Reports',
    headline: 'Gain critical insights about your pipeline health and performance',
    descriptionItems: [
      'Create revenue projections designed for GovCon',
      'Customizable dashboard to track metrics that matter',
    ],
    imageSrc: ReportFeatureImage,
  },
  {
    name: 'Relationship Management',
    headline: 'Leverage your relationships to win more deals',
    descriptionItems: [
      'NextStage keeps track of your teaming partners and competitors',
      'Track key relationships with government decision-makers',
      'Search for the ideal teaming partner and conduct competitor analysis',
      'Execute call plans to nurture relationships',
    ],
    imageSrc: CrmFeatureImage,
  },
]

const defaultHeroHeadline = (
  <div>
    Find, qualify, and win <b>more</b> <br /> government contracts
  </div>
)
const defaultHeroSubHeadline = (
  <div>
    NextStage automates the busy-work of GovCon business development so you can focus on
    PWin-enhancing activity.
  </div>
)

const defaultProductHeadline = (
  <div>
    The CRM built for <b>government contractors</b>
  </div>
)
const defaultProductSubHeadline = (
  <div>
    NextStage is the one-stop shop for market intelligence, task order management, capture,
    and proposal workflows
  </div>
)

const cta = ''

interface LandingPageProps {
  heroHeadline?: any
  heroSubHeadline?: any
  productHeadline?: any
  productSubHeadline?: any
  productFeatureSections?: ProductFeatureProps[]
  cta?: string
}

export const LandingPage = (props: LandingPageProps) => {
  const {
    heroHeadline = defaultHeroHeadline,
    heroSubHeadline = defaultHeroSubHeadline,
    productHeadline = defaultProductHeadline,
    productSubHeadline = defaultProductSubHeadline,
    productFeatureSections = defaultProductFeatureSections,
    cta,
  } = props
  return (
    <LandingPageContainer>
      <div className={styles.hero}>
        <h1 className={styles.header}>{heroHeadline}</h1>
        <h2 className={styles.heroSubheader}>{heroSubHeadline}</h2>
        <div className="flexBoxCol">
          {cta && <span className={styles.heroSubheader}>{cta}</span>}
          <div className="flexBoxRow">
            <CallToActionButton />
            <a href="#product" style={{ margin: 'auto 2em' }}>
              Learn more
            </a>
          </div>
        </div>
      </div>
      <HeroImage />
      <div className="margin-top-2">
        <CustomerLogos />
      </div>

      <div id="product" className={styles.fullWidthMessage}>
        <div className="flexBoxCol">
          <h1 className={styles.header}>{productHeadline}</h1>
          <h2 className={styles.fullWidthMessageSubheader}>{productSubHeadline}</h2>
        </div>
      </div>

      <div className={styles.productFeaturesContainer}>
        {productFeatureSections.map((sectionProps, index) => (
          <Fragment key={`${sectionProps.name}_${index}`}>
            {index == 1 && (
              <>
                <InteractiveOpportunityManagementSection />
                <TestimonialSection />
              </>
            )}
            <ProductFeature {...sectionProps} />
          </Fragment>
        ))}
      </div>

      <FeatureGrid />

      <div className={styles.ctaSection}>
        <h3 className={styles.subheader} style={{ marginBottom: '1rem' }}>
          Ready to get started?
        </h3>

        <CallToActionButton />
      </div>

      <Divider />
      <div className={styles.section}>
        <h3
          className={styles.subheader}
          style={{ marginBottom: '1rem', textAlign: 'center' }}
        >
          Task order management and integrations
        </h3>
        <div className={styles.integrations}>
          {integrationLogos.map((l) => (
            <div key={l.alt} className={styles.integrationLogo}>
              <Image src={l.src} alt={l.alt} />
            </div>
          ))}
        </div>
      </div>
      <Divider />
      <div
        className={styles.section}
        style={{
          marginBottom: '0rem',
          marginTop: '0rem',
          maxWidth: '48rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingBottom: '1rem',
        }}
      >
        <h3
          className={styles.subheader}
          style={{ marginBottom: '0rem', textAlign: 'center' }}
        >
          About us
        </h3>
        <p className={styles.textLg}>
          We're passionate about making it easier for businesses to work with the
          government. Selling to the government is complicated and expensive. Our mission is
          to make the sales cycle more efficient with technology.
        </p>
      </div>

      <div className={styles.section} style={{ marginBottom: '0rem', marginTop: '0rem' }}>
        <h3
          className={styles.subheader}
          style={{ marginBottom: '0rem', textAlign: 'center' }}
        >
          Learn more
        </h3>
        <p className={styles.textLg} style={{ margin: '0.5rem 0', textAlign: 'center' }}>
          Have questions or want to book a demo? We'd love to get in touch
        </p>
        <div className="flexBoxCol">
          <span
            className={styles.text}
            style={{ fontSize: '20px', marginBottom: '1rem', textAlign: 'center' }}
          >
            Email: <a href="mailto:hello@nextstage.ai">hello@nextstage.ai</a>
          </span>
          <span className={styles.text} style={{ fontSize: '20px', textAlign: 'center' }}>
            Phone: 415-658-9046
          </span>
          <a
            href="https://calendly.com/rye-j/30min"
            className={styles.text}
            style={{
              fontSize: '20px',
              marginTop: '1rem',
              color: '#2d9cdb',
              textAlign: 'center',
            }}
          >
            Book a meeting with us
          </a>
        </div>
      </div>
    </LandingPageContainer>
  )
}

export default LandingPage
